import React, { useState, useEffect } from 'react';
import './Navibar.css';
import Segments from './Segments.js';
import { useLocation } from 'react-router-dom';

const NaviBar = () => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [inputValues1, setInputValues] = useState(['', '', '', '', '', '']);
  const [projectDetails, setprojectDetails] = useState([]);
  const [projectNameExists, setProjectNameExists] = useState(false);
  const allFieldsFilled = inputValues1.every((value) => value !== '');
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const items = [
    'UNIT', 'YEAR', 'MONTH', 'PROJECT NAME', 'JOB NUMBER', 'CLIENT'
  ];
  const unitOptions = ['4SIGHT UAE', '4SIGHT KSA'];

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;

    if (items[index] === 'YEAR' && isNaN(newValue)) {
      return;
    }
    if (items[index] === 'YEAR' && newValue.length > 4) {
      return;
    }
    if (items[index] === 'JOB NUMBER') {
      if (newValue.length <= 2) {
        if (!/^[A-Za-z]*$/.test(newValue)) {
          return;
        }
      } else {
        if (!/^\d+$/.test(newValue.slice(2))) {
          return;
        }
      }
    }

    const newInputValues = [...inputValues1];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);
    console.log(newInputValues)

    if (index === 3) {
      checkProjectNameExists(newValue);
    }
  };

  const checkProjectNameExists = (projectName) => {
    const existingProjectName = projectDetails.find((project) => project.projectName === projectName);
    setProjectNameExists(!!existingProjectName);
  };

  useEffect(() => {
    

    const fetchprojectDetails = async () => {
      
        try {
        const response = await fetch('https://4sightoperations.in/api/project-names/');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        const projectDetails = data.map(([projectName, jobNumber, unit, year, month, last_segment_number]) => ({
          projectName, jobNumber, unit, month, year, last_segment_number
        }));
        setprojectDetails(projectDetails);
      } catch (error) {
        console.error('Error fetching project names:', error);
      }
    };

    fetchprojectDetails();
  }, []);

  useEffect(() => {
   
    if (location.state?.inputValues) {
      setInputValues(location.state.inputValues);
    }
  }, [location.state]);

  return (
    <div>
      <div className="navbar">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {activeIndex === index ? (
              <div className="form-controll">
                {item === 'MONTH' ? (
                  <select
                    className="form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                  >
                    <option value="" hidden>Select Month</option>
                    {months.map((month, i) => (
                      <option key={i} value={month}>{month}</option>
                    ))}
                  </select>
                ) : item === 'UNIT' ? (
                  <select
                    className="form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                  >
                    <option value="" hidden>Select Unit</option>
                    {unitOptions.map((unit, i) => (
                      <option key={i} value={unit}>{unit}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                  />
                )}
              </div>
            ) : (
              <button
                onClick={() => handleItemClick(index)}
                className="btn btn-outline-secondary sidebar-button"
              >
                {inputValues1[index] ? `${item}: ${inputValues1[index]}` : `${item}:`}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
      <Segments inputValues1={inputValues1} allFieldsFilled={allFieldsFilled} disabled={projectNameExists} />
    </div>
  );
};

export default NaviBar;
