import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './Conclusion.css';

function Conclusion() {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);

    return(
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'200px'}}>
            <div 
                className={`d-flex flex-column justify-content-center w-40 p-4 ${visible ? 'zoom-in1' : 'zoom-out1'} con`}
                style={{border:'1px solid grey',
                 backgroundColor: 'rgba(255, 255, 255, 1)', backdropFilter: 'blur(10px)', color:'black', 
                 boxShadow:'1px 1px 10px rgba(1,1,1,1)'}}
            >
                <h4 style={{display:'flex', justifyContent:'center', alignItems:'center', color:'black'}}>
                Thank You</h4>
                <h4 style={{display:'flex', justifyContent:'center', alignItems:'center', color:'black'}}>
                Your Responses are Recorded!</h4>
            </div>
        </div>
    );
}

export default Conclusion;
