import React, { useState, useEffect } from 'react';
import './Navibar.css';
import './Navibar2.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

const Navibar2 = ({ segmentCount, onSegmentCountChange, onSubmit, isEditing, allFieldsFilled, disabled  }) => {
  const location = useLocation();
  const segments = location.state?.segments;
  const inputValues = location.state?.inputValues;
  const [inputValue, setInputValue] = useState(inputValues ? inputValues[6] : null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const items = ['NO OF SEGMENTS'];

  const navigate = useNavigate();

  useEffect(() => {
    if (segments) {
      onSubmit(); 
    }
  }, [segments, onSubmit]);

  const handleInputChange = (event) => {
    if (!isEditing) {
    const newValue = event.target.value;
    setInputValue(newValue);
    onSegmentCountChange(parseInt(newValue, 10));
  }
  };

  const handleSubmission = () => {
    if (!allFieldsFilled) {
      alert('Please fill All the Required Fields!');
    } else if (disabled) {
      alert('Project Name Already Exists, Try New One!');
    } else {
      onSubmit(parseInt(inputValue, 10));
      scroll.scrollToBottom({
        duration: 1,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
    if (event.key === 'ArrowRight') {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
    if (event.key === 'ArrowLeft') {
      setActiveIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div>
    <div className="form-controll1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
     backgroundColor: 'lightBlue', color: 'white',  width: '100%', height: '60px' }}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {activeIndex === index ? (
            <div className="form-controll">
              <input
                type="text"
                className="form-controll form-inputt"
                id="segment-count"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(event) => handleKeyPress(event, index)}
                onBlur={() => setActiveIndex(-1)}
                autoFocus
                required
                readOnly={isEditing}
              />
              <label htmlFor="segment-count">
                {/* {item.split('').map((char, i) => (
                  <span key={i} style={{ transitionDelay: `${i * 5}ms` }}>
                    {char}
                  </span>
                ))} */}
              </label>
            </div>
          ) : (
            <button
              onClick={() => handleItemClick(index)}
              className="sidebar-button"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid grey',
                backgroundColor: 'white',
                color: 'black',
                cursor: 'pointer',
                transition: 'all 0.1s',
                width: 'auto',
                height: '40px',
                position: 'relative',
                marginLeft: '30px',
                borderRadius: '10px',
              }}
            >
              {item}: {inputValue}

            </button>
          )}
        </React.Fragment>
      ))}
      </div>
      {inputValue !== inputValues?.[6] && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
     backgroundColor: 'lightBlue', color: 'white',  width: '100%', height: '60px' }}>
        <button className="animated-button" onClick={handleSubmission}>
          <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
            ></path>
          </svg>
          <span className="text">Proceed</span>
          <span className="circle"></span>
          <svg viewBox=" 0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
            ></path>
          </svg>
        </button>
      </div>
      )}
      <div style={{display:'flex', justifyContent:'end', marginTop:'5px', marginRight:'10px'}}>
      <h5 style={{marginTop:'5px'}}>Calendar:</h5>
      <span style={{marginLeft:'5px'}}></span>
      <button className="button7" onClick={() => navigate('/calendar')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 24 24" height="24" fill="none" class="svg-icon"><g stroke-width="2" stroke-linecap="round" stroke="black"><rect y="5" x="4" width="17" rx="2" height="16"></rect><path d="m8 3v4"></path><path d="m16 3v4"></path><path d="m4 11h16"></path></g></svg>
      </button>
      </div>
    </div>

  );
};

export default Navibar2;