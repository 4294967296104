// Components/ProtectedRoute.js
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../Components/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, loading } = useContext(AuthContext);

  // Wait for loading to complete
  if (loading) {
    return null; // Render nothing or a loading indicator while waiting
  }

  console.log("ProtectedRoute - Verified isLoggedIn:", isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
