import React from "react";
import {Button} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import AWNimg from './compliance.png';
import FSimg from './checklist.png';
import JTimg from './job.png';
import NNorms from './norms.jpg';
import './Operations.css';

function Start() {
    const navigate = useNavigate();

    const handleNavi1 = () => {
        navigate('/awn');
    }

    const handleNavi2 = () => {
        navigate('/survey_details');
    }

    const handleNavi3 = () => {
        navigate('/projects_list');
    }
    const handleNavi4 = () => {
        navigate('/home');
    }
    return(
        <div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor: 'rgba(255, 255, 255, 0.8)', 
        marginBottom:'20px', padding:'5px'}}>
           
        </div>
        <br />
        {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <h4 style={{backgroundColor:'#96C9F4', padding:'10px', color:'black', fontFamily: 'Arial, sans-serif'}}>
            Pick An Option To Continue:</h4>
        </div> */}
        <br />
        <br />
        <div className="button-container" style={{width:'350px', border:'1px solid lightgrey', borderRadius:'10px',
        margin:'auto', padding:'20px 20px 20px 20px', backgroundColor:'white'}}>
        <div className="divSpace">
        <div style={{justifyContent:'center', display:'flex'}}>
            <Button className="color"  onClick={handleNavi1}>
            <div style={{display:'flex', justifyContent:'start'}}>
            <img className="img" src={AWNimg} style={{width:'30px', marginRight:'10px', marginLeft:'10px'}}></img>
            Analysis Warning Note
            </div>
            </Button> 
            </div>
            <br />
            <div style={{justifyContent:'center', display:'flex'}}>
            <Button className="color1"  onClick={handleNavi2}>
            <div style={{display:'flex', justifyContent:'start'}}>
            <img className="img" src={FSimg} style={{width:'30px', marginRight:'10px', marginLeft:'10px'}}></img>
            FeedBack Survey
            </div>
            </Button>
            </div>
            <br />
            <div style={{justifyContent:'center', display:'flex'}}>
            <Button className="color2"  onClick={handleNavi3}>
            <div style={{display:'flex', justifyContent:'start'}}>
            <img className="img" src={JTimg} style={{width:'30px', marginRight:'10px', marginLeft:'10px'}}></img>
            Job Tracker
            </div>
            </Button>
            
            </div>
            <br />
            <div style={{justifyContent:'center', display:'flex'}}>
            <Button className="color2"  onClick={handleNavi4}>
            <div style={{display:'flex', justifyContent:'start'}}>
            <img className="img" src={NNorms} style={{width:'30px', marginRight:'10px', marginLeft:'10px'}}></img>
            Norms
            </div>
            </Button>
            
            </div>
            </div>
        </div>
        </div>
    );
}

export default Start;