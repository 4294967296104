import React, { useState, useEffect } from 'react';
import FileUpload from './ExcelUpload';


function Home() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await fetch('https://4sightoperations.in/data/data/', {
  //       method: 'GET',
  //       headers: {
  //           Authorization: `Token ${localStorage.getItem('Token')}`,
  //       },
  //     });
  //     const jsonData = await response.json();
  //     setData(jsonData);
  //     console.log(jsonData)
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await fetch('https://4sightoperations.in/data/data/', {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('Token')}`,
        },
      });
  
      // Check if the response is unauthorized
      if (response.status === 401) {
        console.error('Unauthorized: Check token or permissions');
        return;
      }
  
      // Check if CORS error is bypassed but still returns an empty response
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const jsonData = await response.json();
      setData(jsonData);
      console.log(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  // const fetchData = async () => {
  //   try {
  //     const response = await fetch('https://4sightoperations.in/data/data/', {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Token ${localStorage.getItem('Token')}`,
  //       },
  //       mode: 'no-cors'  // Only for testing purposes
  //     });
  //     const jsonData = await response.json();
  //     setData(jsonData);
  //     console.log(jsonData);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  
  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <br />
      <FileUpload />
    </div>
  );
  
}



export default Home;
