import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion, Card, Table } from 'react-bootstrap';
import './SegView.css';

function SegView() {
  const location = useLocation();
  const { project } = location.state || {};

  useEffect(() => {
    if (!project) {
      return <div className="error-message">No project data available.</div>;
    }
  }, [project]);

  return (
    <div className="seg-view-container" style={{ width: '80%', margin: 'auto' }}>
      <div style={{ width: '100%', borderBottom: '1px solid grey', marginBottom: '10px', marginTop: '5px' }}>
        <h4 className="project-title" style={{ fontFamily: "'Montserrat', sans-serif" }}>
          Project: <span style={{ color: '#2b5278', marginLeft: '10px' }}>{project.projectName}</span>
        </h4>
      </div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>PROJECT DETAILS</Accordion.Header>
          <Accordion.Body>
            <Table bordered hover>
              <tbody>
                <tr><td className="tD1">Segment Name:</td><td className="tD2">{project.segment_name}</td></tr>
                <tr><td className="tD1">Unit:</td><td className="tD2">{project.unit}</td></tr>
                <tr><td className="tD1">Year:</td><td className="tD2">{project.year}</td></tr>
                <tr><td className="tD1">Month:</td><td className="tD2">{project.month}</td></tr>
                <tr><td className="tD1">Job Number:</td><td className="tD2">{project.jobNumber}</td></tr>
                <tr><td className="tD1">Client:</td><td className="tD2">{project.client}</td></tr>
                <tr><td className="tD1">Segment Count:</td><td className="tD2">{project.last_segment_number}</td></tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>RESEARCH AND ANALYSIS</Accordion.Header>
          <Accordion.Body>
            <Table bordered hover>
              <tbody>
                <tr><td className="tD1">Research Group Head:</td><td className="tD2">{project.research_group_head}</td></tr>
                <tr><td className="tD1">Contact Person In Research:</td><td className="tD2">{project.contact_person_in_research}</td></tr>
                <tr><td className="tD1">Analysis Group Head:</td><td className="tD2">{project.analysis_group_head}</td></tr>
                <tr><td className="tD1">Contact Person In Analysis:</td><td className="tD2">{project.contact_person_in_analysis}</td></tr>
                <tr><td className="tD1">Actual Sample Size:</td><td className="tD2">{project.actual_sample_size}</td></tr>
                <tr><td className="tD1">Achieved Sample Size:</td><td className="tD2">{project.achieved_sample_size}</td></tr>
                <tr><td className="tD1">Final Processed Samples:</td><td className="tD2">{project.final_processed_samples}</td></tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>TIMELINE</Accordion.Header>
          <Accordion.Body>
            <Table bordered hover>
              <tbody>
                <tr><td className="tD1">Expected Date of Output:</td><td className="tD2">{project.expectedDateOfOutput}</td></tr>
                <tr><td className="tD1">Actual Final Output:</td><td className="tD2">{project.actual_final_output}</td></tr>
                <tr><td className="tD1">Date Of AWN:</td><td className="tD2">{project.date_of_awn}</td></tr>
                <tr><td className="tD1">Date Of R-AWN1:</td><td className="tD2">{project.date_of_r_awn1}</td></tr>
                <tr><td className="tD1">Final R-AWN Date:</td><td className="tD2">{project.final_r_awn}</td></tr>
                <tr><td className="tD1">Questionnaire Received Date:</td><td className="tD2">{project.questionnaire_received_date}</td></tr>
                <tr><td className="tD1">AP Received Date:</td><td className="tD2">{project.ap_received_date}</td></tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>

        {/* ADVANCED ANALYTICS SECTION */}
        <Accordion.Item eventKey="3">
          <Accordion.Header>ADVANCED ANALYTICS</Accordion.Header>
          <Accordion.Body>
            <Table bordered hover>
              <tbody>
                <tr><td className="tD1">Advanced Analytics:</td><td className="tD2">{project.advanced_analytics}</td></tr>
                <tr><td className="tD1">FW Start Date:</td><td className="tD2">{project.fw_start_date}</td></tr>
                <tr><td className="tD1">FW End Date:</td><td className="tD2">{project.fw_end_date}</td></tr>
                <tr><td className="tD1">Top Lines:</td><td className="tD2">{project.top_lines}</td></tr>
                <tr><td className="tD1">Top Lines Date:</td><td className="tD2">{project.top_lines_date}</td></tr>
                <tr><td className="tD1">OE Coding:</td><td className="tD2">{project.oe_coding}</td></tr>
                <tr><td className="tD1">OE Costing:</td><td className="tD2">{project.oe_costing}</td></tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>

        {/* PLATFORMS SECTION */}
        <Accordion.Item eventKey="4">
          <Accordion.Header>PLATFORMS</Accordion.Header>
          <Accordion.Body>
            <Table bordered hover>
              <tbody>
                <tr><td className="tD1">Survey Platform:</td><td className="tD2">{project.survey_platform}</td></tr>
                <tr><td className="tD1">DP Platform:</td><td className="tD2">{project.dp_platform}</td></tr>
                <tr><td className="tD1">Number Of Response:</td><td className="tD2">{project.number_of_response}</td></tr>
                <tr><td className="tD1">Data Correction Done:</td><td className="tD2">{project.data_correction_done}</td></tr>
                <tr><td className="tD1">Norms Done:</td><td className="tD2">{project.norms_done}</td></tr>
                <tr><td className="tD1">Project Classification:</td><td className="tD2">{project.project_classification}</td></tr>
                <tr><td className="tD1">Feedback Survey Done:</td><td className="tD2">{project.feedback_survey_done}</td></tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>

        {/* STATUS SECTION */}
        <Accordion.Item eventKey="5">
          <Accordion.Header>STATUS</Accordion.Header>
          <Accordion.Body>
            <Table bordered hover>
              <tbody>
                <tr><td className="tD1">Status:</td><td className="tD2">{project.status}</td></tr>
                <tr><td className="tD1">LOI:</td><td className="tD2">{project.loi}</td></tr>
                <tr><td className="tD1">Check List Followed:</td><td className="tD2">{project.check_list_followed}</td></tr>
                <tr><td className="tD1">Data Correction Done:</td><td className="tD2">{project.data_correction_done}</td></tr>
                <tr><td className="tD1">Completed Month:</td><td className="tD2">{project.completed_month}</td></tr>
                <tr><td className="tD1">Archive Taken:</td><td className="tD2">{project.archive_taken}</td></tr>
                <tr><td className="tD1">Remarks:</td><td className="tD2">{project.remarks}</td></tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
        
      </Accordion>
    </div>
  );
}

export default SegView;
