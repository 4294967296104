import React, { useState, useEffect } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';
import { useBootstrap } from './useBootstrap';
import axios from 'axios';

const localizer = momentLocalizer(moment);

function Calendar() {
  useBootstrap();

  const [events, setEvents] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTask, setSelectedTask] = useState('');

  useEffect(() => {
    axios.get('/ProjectOptions.txt')
      .then(response => {
        const options = response.data.split('\n').map(option => option.trim()).filter(option => option);
        setProjectOptions(options);
      })
      .catch(error => {
        console.error('Error fetching project options:', error);
      });

    axios.get('/TaskOptions.txt')
      .then(response => {
        const options = response.data.split('\n').map(option => option.trim()).filter(option => option);
        setTaskOptions(options);
      })
      .catch(error => {
        console.error('Error fetching task options:', error);
      });
  }, []);

  const handleSelectSlot = ({ start, end }) => {
    const dates = [];
    const times = [];
    let currentDate = new Date(start);
  
    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    setSelectedDates(dates);
  
    let currentTime = new Date(start);
    while (currentTime < end) {
      times.push({
        start: new Date(currentTime),
        end: new Date(currentTime.getTime() + 30 * 60 * 1000)
      });
      currentTime.setTime(currentTime.getTime() + 30 * 60 * 1000);
    }
  
    setSelectedTimeSlots(times);
    setShowAddModal(true);
  };
  

  const handleAddModalClose = () => {
    setShowAddModal(false);
    setSelectedDates([]);
    setSelectedProject('');
    setSelectedTask('');
  };

  const formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
  };

  const formatTime = (startTime, endTime) => {
    return `${moment(startTime).format('hh:mmA')} - ${moment(endTime).format('hh:mmA')}`;
  };

  const handleAddModalSubmit = () => {
    if (selectedDates.length > 0 && selectedTimeSlots.length > 0 && (selectedProject || selectedTask)) {
      const startTimes = selectedTimeSlots.map(slot => slot.start);
      const endTimes = selectedTimeSlots.map(slot => slot.end);
      const earliestStart = new Date(Math.min(...startTimes));
      const latestEnd = new Date(Math.max(...endTimes));
  
      const startDate = new Date(selectedDates[0].setHours(earliestStart.getHours(), earliestStart.getMinutes(), earliestStart.getSeconds()));
      const endDate = new Date(selectedDates[selectedDates.length - 1].setHours(latestEnd.getHours(), latestEnd.getMinutes(), latestEnd.getSeconds()));
  
      const eventTitle = selectedProject && selectedTask
        ? `${selectedProject}, ${selectedTask}`
        : selectedProject
        ? selectedProject
        : selectedTask
        ? selectedTask
        : '';
  
      const newEvent = {
        id: events.length + 1,
        title: eventTitle,
        start: startDate,
        end: endDate,
      };

      const durationMinutes = (endDate.getTime() - startDate.getTime()) / (1000 * 60);
  
      setEvents([...events, newEvent]);
      handleAddModalClose();
  
      axios.post('/api/events/batch', {
        Project: selectedProject || null,
        Task: selectedTask || null,
        StartDate: formatDate(startDate),
        EndDate: formatDate(endDate - 1),
        Time: formatTime(newEvent.start, newEvent.end),
        MinutesSpent: `${durationMinutes}Mins`,
      })
      .catch(error => {
        console.error('Error creating event:', error);
      });
    } else {
      alert('Please select options!');
    }
  };
  
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    const [project, task] = event.title.split(', ');
    setSelectedProject(project || '');
    setSelectedTask(task || '');
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedEvent(null);
    setSelectedProject('');
    setSelectedTask('');
  };

  const handleEditModalSubmit = () => {
    if (selectedEvent) {
      const updatedEvents = events.map(event =>
        event.id === selectedEvent.id
          ? {
              ...event,
              title: selectedProject && selectedTask
                ? `${selectedProject}, ${selectedTask}`
                : selectedProject
                ? selectedProject
                : selectedTask
                ? selectedTask
                : event.title,
            }
          : event
      );

      const durationMinutes = (selectedEvent.end.getTime() - selectedEvent.start.getTime()) / (1000 * 60);

      setEvents(updatedEvents);

      axios.post('/api/events/update', {
        id: selectedEvent.id,
        Project: selectedProject || null,
        Task: selectedTask || null,
        StartDate: formatDate(selectedEvent.start),
        EndDate: formatDate(selectedEvent.end - 1),
        Time: formatTime(selectedEvent.start, selectedEvent.end),
        MinutesSpent: `${durationMinutes}Mins`,
      }).catch(error => {
        console.error('Error updating event:', error);
      });

      handleEditModalClose();
    } else {
      alert('Please select options!');
    }
  };

  const handleDeleteEvent = () => {
    if (selectedEvent) {
      const updatedEvents = events.filter(event => event.id !== selectedEvent.id);
      const durationMinutes = (selectedEvent.end.getTime() - selectedEvent.start.getTime()) / (1000 * 60);
      setEvents(updatedEvents);

      axios.post('/api/events/delete', {
        id: selectedEvent.id,
        Project: selectedProject || null,
        Task: selectedTask || null,
        StartDate: formatDate(selectedEvent.start),
        EndDate: formatDate(selectedEvent.end - 1),
        Time: formatTime(selectedEvent.start, selectedEvent.end),
        MinutesSpent: `${durationMinutes}Mins`,
      }).catch(error => {
        console.error('Error deleting event:', error);
      });

      handleEditModalClose();
    }
  };

  return (
    <div>
      <div style={{ height: '100vh'}}>
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="week"
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleEventClick}
          step={30}
          timeslots={1}
        />
        {showAddModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ marginTop: '150px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New Event</h5>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleAddModalClose}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h5>Project Name:</h5>
                  <select
                    className="form-control mb-2"
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(e.target.value)}
                  >
                    <option value="" hidden>Select Project</option>
                    <option value="project1" >Project 1</option>
                    <option value="project2" >Project 2</option>
                    <option value="project3" >Project 3</option>
                    {projectOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                  <br />
                  <h5>Task Name:</h5>
                  <select
                    className="form-control mb-2"
                    value={selectedTask}
                    onChange={(e) => setSelectedTask(e.target.value)}
                  >
                    <option value="" hidden>Select Task</option>
                    <option value="task1" >Task 1</option>
                    <option value="task2" >Task 2</option>
                    <option value="task3" >Task 3</option>
                    {taskOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleAddModalSubmit}
                  >
                    Create Event
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleAddModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showEditModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ marginTop: '150px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Event</h5>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleEditModalClose}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h5>Project Name:</h5>
                  <select
                    className="form-control mb-2"
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(e.target.value)}
                  >
                    <option value="" hidden>Select Project</option>
                    <option value="project1" >Project 1</option>
                    <option value="project2" >Project 2</option>
                    <option value="project3" >Project 3</option>
                    {projectOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                  <h5>Task Name:</h5>
                  <select
                    className="form-control mb-2"
                    value={selectedTask}
                    onChange={(e) => setSelectedTask(e.target.value)}
                  >
                    <option value="" hidden>Select Task</option>
                    <option value="task1" >Task 1</option>
                    <option value="task2" >Task 2</option>
                    <option value="task3" >Task 3</option>
                    {taskOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={handleEditModalSubmit}
                  >
                    Save Changes
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteEvent}
                  >
                    Delete Event
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleEditModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Calendar;
