import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './ProjectList.css';

const ProjectList = () => {
  const [projectNames, setProjectNames] = useState([]);
  const [filters, setFilters] = useState({
    unit: '',
    year: '',
    month: '',
    projectName: '',
    jobNumber: '',
    segmentNumber: '',
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectNames = async () => {
      try {
        const response = await fetch('https://4sightoperations.in/api/project-names/');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();

        const projectNames = data.map(([projectName, jobNumber, unit, year, month, last_segment_number,
          expectedDateOfOutput, segmentNumber, client, completed_month, data_correction_done, status,
          research_group_head, contact_person_in_research, analysis_group_head, contact_person_in_analysis,
          actual_final_output, check_list_followed, feedback_survey_done, remarks, archive_taken, loi,
          number_of_response, oe_costing, oe_coding, top_lines, fw_end_date, fw_start_date, ap_received_date,
          questionnaire_received_date, top_lines_date, final_r_awn, date_of_r_awn1, date_of_awn, final_processed_samples,
          achieved_sample_size, actual_sample_size, advanced_analytics, norms_done, survey_platform, dp_platform,
          project_classification, segment_name]) => {

            const formatOrBlank = (date) => {
              const formattedDate = moment(date, ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"], true);
              return formattedDate.isValid() ? formattedDate.format("DD/MM/YYYY") : '';
            };

            return {
              projectName,
              jobNumber,
              unit,
              month,
              year,
              last_segment_number,
              expectedDateOfOutput: formatOrBlank(expectedDateOfOutput),
              segmentNumber,
              client,
              completed_month,
              data_correction_done,
              status,
              research_group_head,
              contact_person_in_research,
              analysis_group_head,
              contact_person_in_analysis,
              actual_final_output: formatOrBlank(actual_final_output),
              check_list_followed,
              feedback_survey_done,
              remarks,
              archive_taken,
              loi,
              number_of_response,
              oe_costing,
              oe_coding,
              top_lines,
              fw_end_date: formatOrBlank(fw_end_date),
              fw_start_date: formatOrBlank(fw_start_date),
              ap_received_date: formatOrBlank(ap_received_date),
              questionnaire_received_date: formatOrBlank(questionnaire_received_date),
              top_lines_date: formatOrBlank(top_lines_date),
              final_r_awn: formatOrBlank(final_r_awn),
              date_of_r_awn1: formatOrBlank(date_of_r_awn1),
              date_of_awn: formatOrBlank(date_of_awn),
              final_processed_samples,
              achieved_sample_size,
              actual_sample_size,
              advanced_analytics,
              norms_done,
              survey_platform,
              dp_platform,
              project_classification,
              segment_name
            };
        });

        setProjectNames(projectNames);
      } catch (error) {
        console.error('Error fetching project names:', error);
      }
    };

    fetchProjectNames();
  }, []);

  const handleFilterChange = (column, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  const handleDateFilterChange = (dateType, date) => {
    if (dateType === 'start') {
      setStartDate(date);
    } else if (dateType === 'end') {
      setEndDate(date);
    }
  };

  const filteredProjects = projectNames.filter((project) => {
    const withinTextFilters = Object.keys(filters).every((key) =>
      project[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
    );

    const projectDate = moment(project.expectedDateOfOutput, "DD/MM/YYYY", true);
    const isWithinDateRange =
      (!startDate || projectDate.isSameOrAfter(moment(startDate, "YYYY-MM-DD"))) &&
      (!endDate || projectDate.isSameOrBefore(moment(endDate, "YYYY-MM-DD")));

    return withinTextFilters && isWithinDateRange;
  });

  const handleEdit = async (projectName) => {
    try {
      const response = await fetch(`https://4sightoperations.in/api/segments/${projectName}/`);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const segments = await response.json();
      if (segments.length === 0) {
        console.error('No segments found for this project.');
        return;
      }

      const inputData = [
        segments[0].unit || '',
        segments[0].year || '',
        segments[0].month || '',
        segments[0].project_name || '',
        segments[0].job_number || '',
        segments[0].client || '',
        segments.length,
      ];

      const inputValuesState = segments.map((segment) => {
        const segmentValues = {};
        Object.keys(segment).forEach((key) => {
          if (key.includes('DATE')) {
            const date = moment(segment[key]);
            segmentValues[key] = date.isValid() ? date.toDate() : null;
          } else {
            segmentValues[key] = segment[key];
          }
        });
        return segmentValues;
      });

      navigate('/edit', { state: { inputValues: inputData, segments: inputValuesState, isEditing: true } });
    } catch (error) {
      console.error('Error fetching input values:', error);
    }
  };

  const handleShowSegments = () => {
    navigate('/create-new');
  };

  const handleView = (project) => {
    navigate('/project_detailed_view', { state: { project } });
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md={10} className="table-responsive">
          <Table bordered hover className="mt-3 project-list-table">
            <thead>
              <tr>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter Unit"
                    value={filters.unit}
                    onChange={(e) => handleFilterChange('unit', e.target.value)}
                    className="filter-input"
                  />
                </th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter Year"
                    value={filters.year}
                    onChange={(e) => handleFilterChange('year', e.target.value)}
                    className="filter-input"
                  />
                </th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter Month"
                    value={filters.month}
                    onChange={(e) => handleFilterChange('month', e.target.value)}
                    className="filter-input"
                  />
                </th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter Project Name"
                    value={filters.projectName}
                    onChange={(e) => handleFilterChange('projectName', e.target.value)}
                    className="filter-input"
                  />
                </th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter Job Number"
                    value={filters.jobNumber}
                    onChange={(e) => handleFilterChange('jobNumber', e.target.value)}
                    className="filter-input"
                  />
                </th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter Segment Number"
                    value={filters.segmentNumber}
                    onChange={(e) => handleFilterChange('segmentNumber', e.target.value)}
                    className="filter-input"
                  />
                </th>
                <th>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => handleDateFilterChange('start', e.target.value)}
                    className="filter-input"
                  />
                  <Form.Control
                    type="date"
                    placeholder="End Date"
                    value={endDate}
                    onChange={(e) => handleDateFilterChange('end', e.target.value)}
                    className="filter-input"
                  />
                </th>
                <th>View</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {filteredProjects.map((project, index) => (
                <tr key={index}>
                  <td>{project.unit}</td>
                  <td>{project.year}</td>
                  <td>{project.month}</td>
                  <td>{project.projectName}</td>
                  <td>{project.jobNumber}</td>
                  <td>{project.segmentNumber}</td>
                  <td>{project.expectedDateOfOutput}</td>
                  <td>
                    <Button variant="link" onClick={() => handleView(project)} className="icon-button">
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </td>
                  <td>
                    <Button variant="link" onClick={() => handleEdit(project.projectName)} className="icon-button">
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="text-center mt-3">
            <Button className="fontButton" onClick={handleShowSegments}>Create New!</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectList;
