// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Awn1 from './Components/Awn1';
import Operations from './Components/Operations';
import SurveyDetails from './Components/SurveyDetails';
import Survey from './Components/Survey';
import './App.css';
import SegView from './Components/SegView';
import NaviBar from './Components/Navibar';
import ProjectList from './Components/ProjectList';
import Calendar from './Components/Calendar';
import Conclusion from './Components/Conclusion';
import Home from './Components/Home';
import DisplayData from './Components/DisplayData';
import Login from './Components/Login';
import CrossTab from './Components/CrossTab';
import SideNavbar from './Components/SideNavbar';
import NavigationBar from './Components/NavigationBar';
import Chatbot from './Components/Chatbot';
import Norms from './Components/Norms';
import Filters from './Components/Filters';
import FileUpload from './Components/ExcelUpload';
import SignUpForm from './Components/SignUpForm';
import ProtectedRoute from './Components/ProtectedRoute';
import AuthContext, { AuthProvider } from './Components/AuthContext';

function App() {
  const [fileData, setFileData] = useState([]); // Define fileData and setFileData here if required

  return (
    <AuthProvider>
      <Router>
        <AppContent fileData={fileData} setFileData={setFileData} />
      </Router>
    </AuthProvider>
  );
}

const AppContent = ({ fileData, setFileData }) => {
  const location = useLocation();

  // Define paths where the sidebar should be shown
  const sidebarPaths = [
    '/home',
    '/display-data',
    '/upload',
    '/cross-tab',
    '/chatbot',
    '/norms',
    '/filters',
    // Add other paths where the sidebar should be shown
  ];

  // Check if the current path matches one of the sidebar paths
  const showSidebar = sidebarPaths.some(path => location.pathname.startsWith(path));

  return (
    <>
      <NavigationBar />
      <Container fluid>
        <Row>
          {/* Render the sidebar only for specific routes */}
          <AuthContext.Consumer>
            {({ isLoggedIn }) =>
              isLoggedIn && showSidebar && (
                <Col sm={1} className="p-0">
                  <SideNavbar />
                </Col>
              )
            }
          </AuthContext.Consumer>
          <Col sm={showSidebar ? 11 : 12} className="p-0">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Operations />} />
              <Route path="/awn" element={<Awn1 />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUpForm />} />

              {/* Protected Routes */}
              <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route path="/display-data/:fileName" element={<ProtectedRoute><DisplayData fileData={fileData} /></ProtectedRoute>} />
              <Route path="/upload" element={<ProtectedRoute><FileUpload setFileData={setFileData} /></ProtectedRoute>} />
              <Route path="/cross-tab" element={<ProtectedRoute><CrossTab /></ProtectedRoute>} />
              <Route path="/chatbot" element={<ProtectedRoute><Chatbot /></ProtectedRoute>} />
              <Route path="/norms" element={<ProtectedRoute><Norms /></ProtectedRoute>} />
              <Route path="/filters" element={<ProtectedRoute><Filters /></ProtectedRoute>} />
              <Route path="/survey_details" element={<ProtectedRoute><SurveyDetails /></ProtectedRoute>} />
              <Route path="/projects_list" element={<ProtectedRoute><ProjectList /></ProtectedRoute>} />
              <Route path="/satisfactionsurvey/:surveyId" element={<ProtectedRoute><Survey /></ProtectedRoute>} />
              <Route path="/create-new" element={<ProtectedRoute><NaviBar /></ProtectedRoute>} />
              <Route path="/edit" element={<ProtectedRoute><NaviBar /></ProtectedRoute>} />
              <Route path="/calendar" element={<ProtectedRoute><Calendar /></ProtectedRoute>} />
              <Route path="/conclusion" element={<ProtectedRoute><Conclusion /></ProtectedRoute>} />
              <Route path="/project_detailed_view" element={<ProtectedRoute><SegView /></ProtectedRoute>} />

              {/* Redirect any undefined paths to home */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default App;
