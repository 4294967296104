// Components/Login.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import AuthContext from '../Components/AuthContext';

const Login = () => {
  const { handleLogin } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post('https://4sightoperations.in/users/login/', {
            email: email,
            password: password
        });

        console.log('Backend response:', response.data); // Log response structure

        const { success, username, token } = response.data;

        if (success) {
            handleLogin(username || email, token); // Pass to context
            localStorage.setItem('Token', token);  // Store token
            navigate('/');
        } else {
            setError('Invalid email or password');
        }
    } catch (error) {
        console.error("Login error:", error.response?.data || error.message); // Log error details
        setError(error.response?.data?.error || 'Invalid email or password'); // Show user-friendly message
    }
};


  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div style={{ width: '300px' }}>
        <Form onSubmit={handleLoginSubmit}> 
          <h2>Login</h2>
          <Form.Group controlId="email">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" style={{ marginBottom: '10px' }}>Login</Button>
        </Form>
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
    </Container>
  );
};

export default Login;
